body {
  font-family: Helvetica, Arial, sans-serif
}

.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.App-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.App-link {
  color: #61dafb;
}

.letterList {
  color: #EEE;
  font-size: medium;
}

.controls {
  margin-top: 50vw;
}

.youWin {
  height: 50vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: green; 
  font-size: 100pt;
}