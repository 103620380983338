.board {
    display: grid;
}

@media all and (orientation:portrait) {
    .board {
        grid-template-columns: repeat(4, 1fr);
    }
    .card {
        width: 18vw;
        height: 18vw;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media all and (orientation:landscape) {
    .board {
        grid-template-columns: repeat(8, 1fr);
    }
    .card {
        width: 13vh;
        height: 13vh;
        line-height: 13vh;
    }
}

.card {
    background-color: PaleVioletRed ;
    border: 1px pink  solid;
    outline: 1px solid transparent;
    /* height: 60px;
    width: 60px; */
    margin: 10px;
}

.card.hidden {
    display: hidden;
    border: none;
    background-color: transparent;
}